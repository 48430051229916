import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Checkbox} from '@hpstellar/core';

import WithCompare from './compare-container';
import { Helpers } from '../../core/src/helpers';

import '../css/compare-checkbox.less';

class CompareCheckBox extends Component {
    //product is optional, if provided you can toggle the product in compare
    //otherwise compare button should just open the compare modal
    static defaultProps = {
        product: undefined,
        btnText: 'Compare',
        selectedText: 'Compare',
        onCompareChange: () => {},
    };

    static propTypes = {
        /*product that will display the compare button*/
        product: PropTypes.object.isRequired,
        /*compare button's text when product is not selected*/
        btnText: PropTypes.string,
        /*compare button's text when product is selected*/
        selectedText: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
    }

    checkCompare(compareProducts, product = {}) {
        let { sku } = product;
        let result;
        if (compareProducts.find(prd => prd.sku == sku)) {
            result = 'selected';
            if (compareProducts.length > 1) {
                result = 'clickable';
            }
        } else {
            result = false;
        }
        return result;
    }

    componentDidUpdate(prevProps) {
        const { compareProducts, onCompareChange, product } = this.props;
        const { compareProducts: prevCompareProducts } = prevProps;

        // 'Clear all' on the left menu was clicked. Deselect all cards:
        if (onCompareChange) {
            if (compareProducts.length === 0 && prevCompareProducts.length > 0) {
                onCompareChange(false);
            } else {
                onCompareChange(!!this.checkCompare(compareProducts, product));
            }
        }
    }

    handleSelect() {
        const { toggleCompareProduct, compareProducts, onCompareChange, product } = this.props;
        toggleCompareProduct(product);
        if (onCompareChange) {
            onCompareChange(!!this.checkCompare(compareProducts, product));
        }
    }

    render() {
        const { product, compareProducts, disableCompare, btnText, selectedText, children } = this.props;
        if (disableCompare || Helpers.isGiftCard(product) || Helpers.isCarePack(product)) {
            return null;
        }

        const compareState = !!this.checkCompare(compareProducts, product);
        if (children) {
            return children({ toggleCompare: this.handleSelect, compareChecked: compareState });
        }
        return (
            <Checkbox
                className="compare-checkbox"
                label={compareState ? selectedText : btnText}
                checked={compareState}
                onChange={this.handleSelect}
            />
        );
    }
}

export default WithCompare(CompareCheckBox);
