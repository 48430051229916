import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    fetchCompare,
    toggleCompareModal,
    toggleCompareProduct,
    clearCompareProducts,
    getProductCompare,
    setCompareMaxed,
    getConfigCompare,
    setCompareExpanded,
} from '../compare-actions';

let compareWrapper = Component => props => {
    return <Component {...props} />;
};

const mapStateToProps = (state, ownProps) => {
    const { results, products, modal, errors, resultsV2, expanded } = state.compare;
    const { leftMenu = {} } = state.slugInfo.components;
    const { disableCompare = false } = leftMenu;

    return {
        modalOpen: modal,
        compareProducts: products,
        compareResults: results,
        compareResultsV2: resultsV2,
        disableCompare,
        errors,
        expanded,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchCompare: products => dispatch(fetchCompare(products)),
        toggleCompareModal: toggle => dispatch(toggleCompareModal(toggle)),
        toggleCompareProduct: (product, isV2) => dispatch(toggleCompareProduct(product, isV2)),
        clearCompareProducts: () => dispatch(clearCompareProducts()),
        getProductCompare: () => dispatch(getProductCompare()),
        closeMaxedError: () => dispatch(setCompareMaxed(false)),
        getConfigCompare: catEntryId => dispatch(getConfigCompare(catEntryId)),
        setCompareExpanded: expanded => dispatch(setCompareExpanded(expanded)),
    };
};

const options = {
    pure: false,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), compareWrapper);
