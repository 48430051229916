import React, { useCallback } from 'react';
import useTrackCustomMetric from '../../hooks/useTrackCustomMetric';
import { useBVSettings } from '../../hooks/useSiteConfig';

const MAX_DEPTH = 80;

const BvRatingContent = props => {
    const { name, handleClick, placeHolder, sku, className } = props || {};
    const trackCustomMetric = useTrackCustomMetric();
    const { transformedSKU } = useBVSettings(sku);

    const onClick = useCallback(event => {
        try {
            const { target } = event || {};
            const { id, innerText } = target || {};

            handleClick(event);
            if (id === 'WAR' && innerText === 'Write a review') {
                return trackCustomMetric('linkClick', {
                    event: 'e_linkClick',
                    linkPlacement: 'review',
                    linkId: 'write-review',
                });
            }

            // clicking anywhere else should trigger read-review metrics
            trackCustomMetric('readReview', {
                event: 'e_readReview',
                productName: name,
            });
        } catch (e) {}
    });

    return (
        <div onClick={onClick} className={className}>
            <div data-bv-show="rating_summary" data-bv-product-id={transformedSKU} className="rating-summary-container">
                {placeHolder}
            </div>
        </div>
    );
};

class BvRating extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showBV: true,
            reviewClickHandler: false,
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleHover = this.handleHover.bind(this);
    }

    componentDidMount() {
        this.getReviewBtn(1);
        if (typeof window.bvCallback === 'undefined') {
            window.bvCallback = BV => {
                BV.reviews.on('show', () => {
                    this.props.onRatingsClick && this.props.onRatingsClick();
                });
            };
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.sku !== this.props.sku) {
            this.setState({ showBV: false, reviewClickHandler: false }, () => {
                this.getReviewBtn();
            });
        }
        if (!this.state.showBV) {
            this.setState({ showBV: true });
        }
    }

    handleClick(e) {
        if (e.target.className !== 'bv_button_buttonMinimalist') {
            this.props.onRatingsClick();
        }
    }

    getReviewBtn(depth = 1) {
        if (depth > MAX_DEPTH) {
            return;
        }
        try {
            if (document.querySelector('.bv_button_buttonFull ')) {
                this.reviewBtnList = document.getElementsByClassName('bv_button_buttonFull ');
                this.reviewBtn = this.reviewBtnList[this.reviewBtnList.length - 1];
                this.reviewBtn && this.reviewBtn.addEventListener('click', this.handleClick);
                return;
            }
            this.reviewRowList = document.getElementsByClassName('reviews-row');
            this.reviewRow = this.reviewRowList[this.reviewRowList.length - 1];
            if (!this.reviewRow) {
                setTimeout(() => this.getReviewBtn(++depth), 500);
            } else {
                this.reviewRow.addEventListener('mouseover', this.handleHover);
            }
        } catch (e) {}
    }

    handleHover() {
        if (this.state.reviewClickHandler === false) {
            setTimeout(() => {
                this.reviewBtnList = document.getElementsByClassName('bv_button_buttonFull ');
                this.reviewBtn = this.reviewBtnList[this.reviewBtnList.length - 1];
                this.reviewBtn && this.reviewBtn.addEventListener('click', this.handleClick);
                this.setState({ reviewClickHandler: true });
            }, 200);
        }
    }

    render() {
        const { product, placeHolder, className } = this.props;
        const { name } = product || {};
        return this.state.showBV ? (
            <BvRatingContent
                sku={this.props.sku}
                name={name}
                handleClick={this.handleClick}
                placeHolder={placeHolder}
                className={className}
            />
        ) : null;
    }
}

BvRating.defaultProps = {
    sku: '',
};

export default BvRating;
