import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ImageWithPlaceholder from './image-with-placeholder';
import LazyLoad from 'react-lazyload';


/**
A component for lazyloading images that are below the fold
@examples
```jsx
<LazyLoadImage src="/app/assets/images/product/7DF84AV_1/center_facing.png" offset={300} />
```
@component LazyLoadImage
@import LazyLoadImage
@returns {ReactElement} The rendered component
*/
class LazyLoadImage extends React.Component {

	static propTypes = {
		/**
		 * Image url to be loaded
		 */
		src: PropTypes.string.isRequired,
		/**
		 * Alt text for image
		 */
		alt: PropTypes.string,
		/**
		 * Title for image
		 */
		title: PropTypes.string,
		/**
		 * Custom class for image
		 */
		className: PropTypes.string,
		/**
		 * Offets allows you to add a buffer for when the image loads. This is to help avoid the perception to the custom of the content being lazy loaded
		 */
		offset: PropTypes.number,
		/**
		 * Optimization to stop calculating visibilty after the component has loaded
		 */
		once: PropTypes.bool,
		/**
		 * Placeholder height for image
		 */
		height: PropTypes.number
	}

	static defaultProps = {
		offset: 400,
		once: true
	}

	render() {
		let { customStyle, onLoad, className, imageClassName,
			src, title, alt,
			lazyLoadPlaceholderSrc = "https://www.hp.com/us-en/shop/app/assets/images/uploads/prod/1x1img.PNG157860294413598.png",
			offset, height, once } = this.props;
		let lazyLoadPlaceholder = lazyLoadPlaceholderSrc && <img className="lazyload-placeholder" alt={title ? title : 'lazyload-placeholder'} src={lazyLoadPlaceholderSrc} title={title ? title : null} height={1} />;

		return (
			<Fragment>
				<LazyLoad
					once={once}
					offset={offset}
					height={height}
					resize={true}
					placeholder={lazyLoadPlaceholder || null}
				>
					<ImageWithPlaceholder
						className={className}
						imageClassName={imageClassName}
						src={src}
						alt={alt}
						title={title}
						onLoad={onLoad}
						customStyle={customStyle}
					/>
				</LazyLoad>
				<noscript>
					<style>{`.lazyload-placeholder { display: none; }`}</style>
					<ImageWithPlaceholder
						className={className}
						imageClassName={imageClassName}
						src={src}
						alt={alt}
						title={title}
					/>
				</noscript>
			</Fragment>
		)
	}
}

export default LazyLoadImage 