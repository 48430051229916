import React from 'react';
import { PriceBlock, PunchOut } from '@hpstellar/core';

import { Helpers } from '../../../core/src/helpers';
import { withError } from '../../../shared/components/error-boundary';
import DealViolator from '../../../product/components/deals/deal-violator';
import useProductPrice from '../../../hooks/useProductPrice';
import { getCustomPrefix, getPriceBlockDefaultTranslations } from '../../../product/get-product-props/cart';

const productPrice = ({
    product,
    /** Own price to be used if provided */
    price,
    /** Price object added to product price */
    addOnPrice,
    displayDifference = true,
    displayPercentageDiscount,
    withCTOConfigurations,
    priceTag,
    translations,
    hasPriceSeoText,
    isFullWidth,
    className,
    children: PriceComponent,
    disableCustomSaleText,
    ...otherProps
}) => {
    const { prices } = useProductPrice(product ? [product] : null, { withCTOConfigurations, disableCustomSaleText });
    const priceObj = price || prices[product && product.sku];
    if (!priceObj) {
        return null;
    }
    let {
        regularPrice,
        salePrice,
        priceDifference,
        salePriceSuffix,
        translations: priceObjTranslations,
        discountPercent,
    } = priceObj;
    const regularPriceLabel = Helpers.formatCurrency(regularPrice, undefined, true);
    const salePriceLabel = Helpers.formatCurrency(salePrice, undefined, true);
    const translationObj = {
        salePriceSuffix,
        savingsPrefix: displayPercentageDiscount && discountPercent >= 1 && `${Math.floor(discountPercent)}% OFF`,
        ...getPriceBlockDefaultTranslations(hasPriceSeoText),
        ...(translations || {}),
        ...(priceObjTranslations || {}),
    };
    let productType = product.prdClass || product.product_type;
    const priceAriaLabel =
        `Sale price starting at ${salePriceLabel}.` +
        (priceObj.regularPrice > priceObj.salePrice ? ` Regular price starting at ${regularPriceLabel}` : '');

    //total price should override all mainly because of bundling
    if (addOnPrice) {
        addOnPrice.regularPrice && (regularPrice += addOnPrice.regularPrice);
        addOnPrice.salePrice && (salePrice += addOnPrice.salePrice);
    }

    const punchoutObj = { availableInStock: priceObj?.stock?.toString(), grossMargin: priceObj?.gmPoints };
    if (priceObj.preOrder) {
        punchoutObj.maxQtyLimitPerPreOrder = priceObj.preordermaxqty;
    }

    const isCtoWithSeoText = hasPriceSeoText && typeof productType === 'string' && /cto/i.test(productType);
    const displayFullWidth = !isCtoWithSeoText && isFullWidth;
    const priceBlockProps = {
        hasActiveDeal: priceObj.deal,
        regularPrice,
        salePrice,
        showInCartText: priceObj.inCartPrice,
        tierMsg: priceObj.tierMsg,
        'aria-label': priceAriaLabel,
        productType: ['CTO', 'VGC', 'PGC'].includes(productType) ? 'cto' : productType,
        priceDifference: displayDifference ? priceDifference : null,
        priceTag,
        translations: translationObj,
        bundle: priceObj.bundle,
        customSaleText: priceObj.customSaleText,
        customPrefix: getCustomPrefix(priceObj, product, hasPriceSeoText),
        isFullWidth: displayFullWidth,
        className,
        isLoading: !regularPrice && !salePrice,
        ...otherProps,
    };

    return (
        <>
            <DealViolator price={priceObj} />
            {PriceComponent ? PriceComponent(priceBlockProps) : <PriceBlock {...priceBlockProps} />}
            {priceObj.isccf && <PunchOut {...punchoutObj} />}
        </>
    );
};

export default withError(productPrice);
